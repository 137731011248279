<template>
    <div class="sidebar-thin custom-container">
        <TheSidebar/>

        <ThePageHeadingBar
            :pageHeading="pageHeading"
            :successCardDuration="10000"
        />

        <section class="main">
            <v-form
                ref="form"
                class="purchase-order-multi-create"
                lazyValidation
            >
                <v-file-input
                    ref="fileInput"
                    v-model="models.file"
                    :rules="[ value => !!value ]"
                    :accept="acceptedFileFormats"
                    aria-required="true"
                    class="file-input"
                    hideDetails
                    :label="`Upload File (${acceptedFileFormats})`"
                    outlined
                    validateOnBlur
                />
                <v-checkbox
                    v-model="models.addToDraft"
                    class="add-to-draft"
                    dense
                    hideDetails
                    label="Add to Draft"
                />

                <ClButton
                    :disabled="loading"
                    :loading="loading"
                    color="primary"
                    outlined
                    type="secondary"
                    @click="getTemplate()"
                >
                    <v-icon left>mdi-download</v-icon>
                    Download Template
                </ClButton>

                <div class="purchase-order-multi-create__row">
                    <ClButton
                        :disabled="loading || isFileNull"
                        :loading="loading"
                        class="button-row__preview"
                        color="primary"
                        outlined
                        type="secondary"
                        @click="uploadHandler( true )"
                    >
                        <v-icon left>mdi-eye</v-icon>
                        Preview POs
                    </ClButton>
                    <ClButton
                        :disabled="loading || isFileNull"
                        :loading="loading"
                        class="button-row__create"
                        color="primary"
                        @click="uploadHandler( false )"
                    >
                        <v-icon left>mdi-upload</v-icon>
                        Create POs
                    </ClButton>
                </div>
            </v-form>

            <!-- Error Table -->
            <div
                v-if="hasErrors"
                class="purchase-order-multi-create__errors box-shadow"
            >
                <h2>File Errors</h2>
                <ClTableSimple
                    :headers="errorTableHeaders"
                    :items="errors"
                />
            </div>
        </section>
    </div>
</template>
<script>
// Vuex
import { mapActions, mapGetters, mapState } from 'vuex';

// Components
import ThePageHeadingBar from '@/components/ThePageHeadingBar';
import TheSidebar from '@/views/common/TheSidebar';

export default {
    name: 'MultiCreate',
    components: {
        ThePageHeadingBar,
        TheSidebar,
    },
    data() {
        return {
            pageHeading: 'Purchase Order Multi-Create',

            acceptedFileFormats: '.tsv, .txt',

            models: {
                addToDraft: false,
                file: null,
            },

            errorTableHeaders: [
                {
                    text: 'Error',
                    value: 'error',
                },
                {
                    text: 'Column',
                    value: 'attribute',
                },
                {
                    text: 'Row',
                    value: 'lineNumber',
                },
                {
                    text: 'Value',
                    value: 'value',
                },
            ],
        };
    },
    computed: {
        ...mapGetters( {
            hasErrors: 'PurchaseOrder/MultiCreate/hasErrors',
        } ),
        ...mapState( {
            errors: ( state ) => state.PurchaseOrder.MultiCreate.errors,
            loading: ( state ) => state.PurchaseOrder.MultiCreate.loading,
        } ),
        /** @returns { Boolean } */
        isFileNull() {
            return this.models.file === null;
        },
    },
    async beforeDestroy() {
        await this.clearErrors();
    },
    methods: {
        ...mapActions( {
            clearErrors: 'PurchaseOrder/MultiCreate/clearErrors',
            getTemplate: 'PurchaseOrder/MultiCreate/getTemplate',
            uploadTemplate: 'PurchaseOrder/MultiCreate/uploadTemplate',
        } ),
        async uploadHandler( previewOnly ) {
            if ( !this.isFileNull ) {
                await this.uploadTemplate( {
                    file: this.models.file,
                    addToDraft: this.models.addToDraft,
                    previewOnly,
                } );
            }
        },
    },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/mixins";

.purchase-order-multi-create {
    max-width: 768px;
    display: grid;
    grid-template-columns: 1fr min-content;
    gap: 1rem;
    align-items: center;

    &__row {
        display: flex;
        gap: 1rem;

        &__create {
            margin-left: 1rem;
        }
    }

    &__errors {
        margin-top: 2rem;
        padding: 1rem;
    }

    .button-row {
        margin-top: 2rem;
    }

    @include respond(phone) {
        grid-template-columns: 1fr;
        gap: .5rem;
        .add-to-draft {
            margin-bottom: 1rem;
        }

        &__row {
            flex-direction: column;
            gap: .5rem;

            &__create {
                margin-left: 0;
            }
        }
    }
}
</style>
